<template>
    <div id="FQCChartone" style="height: 100%; width: 100%;"></div>
</template>

<script>
export default {
  data () {
    return {
      option: {
        title: {
          text: this.$t('All.系统统计日期范围内（检验员&状态）')
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'value',
          data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World']
        },
        series: [
          {
            name: '待审',
            type: 'bar',
            barGap: 0,
            label: {
              show: true,
              position: 'top'
            },
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          },
          {
            name: '允收',
            type: 'bar',
            barGap: 0,
            label: {
              show: true,
              position: 'top'
            },
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          },
          {
            name: '暂存',
            type: 'bar',
            barGap: 0,
            label: {
              show: true,
              position: 'top'
            },
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          },
          {
            name: '紧急结案',
            type: 'bar',
            barGap: 0,
            label: {
              show: true,
              position: 'top'
            },
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          }
        ]
      },
      myChart: null
    }
  },
  mounted () {
    this.Getdata(this.$store.state.Login.Factory === 'All' ? 'TC' : this.$store.state.Login.Factory, this.formatDate(new Date()), this.formatDate(new Date()))
  },
  methods: {
    resizechart () {
      if (this.myChart !== null && this.myChart !== undefined) {
        this.myChart.resize()
      }
    },
    // 格式化函数，确保月份和日期为两位数
    formatDate (date) {
      const y = date.getFullYear()
      const m = (date.getMonth() + 1).toString().padStart(2, '0') // 月份加 1，确保两位数
      const d = date.getDate().toString().padStart(2, '0') // 确保日期是两位数
      return `${y}-${m}-${d}`
    },
    async Getdata (cq, monthStart, monthEnd) {
      try {
        const { data: res } = await this.$http.post(`api/Newiqc/Getiqcalluser?Factory=${cq}&Type=NewFQC&Start=${monthStart}&End=${monthEnd}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.option.xAxis.data = res.response.inspectors
        this.option.series = res.response.series

        // 遍历 series 数据并格式化 name
        this.option.series.forEach(item => {
          item.name = this.$PublicJs.State(parseInt(item.name))
        })
        this.myChart = this.$echarts.init(document.getElementById('FQCChartone'))
        this.myChart.setOption(this.option)
        window.addEventListener('resize', this.resizechart)
      } catch (error) {
        return this.$message.error(error)
      }
    }
  }
}
</script>
